import React from "react";
import Content from "../../../src/layout/content/Content";
import Head from "../../../src/layout/head/Head";

const Comingsoon1 = ({ ...props }) => {
  return (
    <React.Fragment>
      <Head title="Coming soon" />
      <Content>
        <h4 className="text-center mt-5">Coming Soon...</h4>
      </Content>
    </React.Fragment>
  );
};

export default Comingsoon1;
