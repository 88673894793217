import React from "react";
import { Card, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

const data = [
  { name: "Hospital Administrator ", amount: "Marina,Dubai" },
  { name: "Medical Director", amount: "Marina,Dubai" },
  { name: "Nurse Practitioner (NP) ", amount: "Marina,Dubai" },
  { name: "Registered Nurse (RN)", amount: "Marina,Dubai" },
  { name: "Clinical Research Coordinator", amount: "Marina,Dubai" },
  { name: "Surgical Technician", amount: "Marina,Dubai" },
  { name: "Radiologic Technologist ", amount: "Marina,Dubai" },
  { name: "Patient Care Coordinator", amount: "Marina,Dubai" },
  { name: "Dietitian/Nutritionist", amount: "Marina,Dubai" },
  { name: "Nursing Assistant", amount: "Marina,Dubai" },
  { name: "Physical Therapist", amount: "Marina,Dubai" },
];

const UserJobs = () => {
  return (
    <Card className="card-full">
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">Recently Searched Jobs</h6>
          </div>
          {/* <div className="card-tools me-n1 mt-n1">
            <div className="card-tools">
              <a href="/jobs" className="link">
                View All
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="card-inner pt-0">
        <ul className="my-n2">
          {data.map((item, index) => (
            <li
              key={index}
              className={`align-center justify-between py-1 gx-1 ${
                index < data.length - 1 ? "border-bottom border-0 border-dashed" : ""
              }`}
            >
              <span className="badge rounded-pill bg-primary">{item.name}</span>
              {/* <div className="lead-text">{item.name}</div> */}
              <div className="sub-text">{item.amount}</div>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default UserJobs;
