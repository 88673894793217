import React from "react";
import { Card, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

const data = [
  {
    title: "Can we help you set up email forwording?Can ....",
    amount: "Abu Bin Ishtiyak",
    progress: "54%",
    theme: "primary",
    daterange: "Dec 22 - Feb 22",
    time: "14 Jan, 2020 at 11:34 AM",
  },
  {
    title: "Can we help you set up email forwording?Can ....",
    amount: "Joe Larson",
    progress: "84%",
    theme: "danger",
    daterange: "Dec 22 - Feb 22",
    time: "18 Jan, 2020 at 1:34 AM",
  },
  {
    title: "Can we help you set up email forwording?Can ....",
    amount: "Jane Montgomery",
    progress: "62%",
    theme: "success",
    daterange: "Dec 22 - Feb 22",
    time: "18 Feb, 2020 at 1:34 AM",
  },
];

const UserEnquires = () => {
  return (
    <Card className="card-full">
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">Your Enquiries</h6>
          </div>
         {/*  <div className="card-tools me-n1 mt-n1">
            <div className="card-tools">
              <a href="/enquiries" className="link">
                View All
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="card-inner pt-0">
        <ul className="gy-4">
          {data.map((item, index) => (
            <li key={index} className={index < data.length - 1 ? "border-bottom border-0 border-dashed" : ""}>
              <div className="mb-1">
                <span className=" lh-1 mb-1 text-head">{item.amount}</span>
                <div className="sub-text">{item.title}</div>
              </div>
              <div className="align-center">
                <div className="sub-text">
                  {" "}
                  <span className="badge badge-dim bg-success">{item.time}</span>
                </div>
                {/* <div className="small text-primary me-2">{item.progress}</div>
                            <div className={`progress progress-md rounded-pill w-100 bg-${item.theme}-dim`}>
                                <div className={`progress-bar bg-${item.theme} rounded-pill`} style={{width:item.progress}}></div>
                            </div> */}
                {/* <UncontrolledDropdown className="ms-3">
                                <DropdownToggle tag="a" type="button" className="dropdown dropdown-toggle dropdown-indicator sub-text" offset="0, 10">{item.daterange}</DropdownToggle>
                                <DropdownMenu end className="text-right">
                                    <ul className="link-list-opt">
                                    <li><a href="#"><span>Dec 22 - Feb 22</span></a></li>
                                    <li><a href="#"><span>Oct 22 - Dec 22</span></a></li>
                                    <li><a href="#"><span>Aug 22 - Oct 22</span></a></li>
                                    <li><a href="#"><span>Jun 22 - Aug 22</span></a></li>
                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default UserEnquires;
