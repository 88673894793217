import React, { useContext, useEffect, useState } from "react";
import { Card, Badge } from "reactstrap";
import axios from "axios";
import { getCurrentUser } from "../../services/auth";

const UserHospitalList = () => {
  const [hospitalData, setHospitalData] = useState("");
  const [facilityEdit, SetFacilityEdit] = useState("");
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);

  const editFacility = (id) => {
    SetFacilityEdit(id);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
      localStorage.setItem("user", JSON.stringify(currentUser));
    } else {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }
  }, []);

  const getHospitalList = () => {
    const USR_TOKEN = localStorage.getItem("userToken");
    if (user) {
      axios
        .get(
          `https://api.hospitalsinuae.com/api/resource/Facility?fields=["name","facility_name","country","location","facility_type","emirate","status"]`,
          {
            headers: { Authorization: USR_TOKEN, "content-type": "application/json" },
          }
        )
        .then((response) => {
          setHospitalData(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      getHospitalList();
    }
  }, [user]);

  return (
    <>
      <Card className="card-full">
        <div className="card-inner">
          <div className="card-title-group">
            <div className="card-title">
              <h6 className="title">Recently Searched facilities</h6>
            </div>
            {/* <div className="card-tools">
              <a href="/facilities" className="link">
                View All
              </a>
            </div> */}
          </div>
        </div>
        <div className="card-inner py-0 mt-n2">
          <div className="nk-tb-list nk-tb-flush nk-tb-dashed">
            <div className="nk-tb-item nk-tb-head">
              <div className="nk-tb-col">
                <span>Facility</span>
              </div>
              <div className="nk-tb-col tb-col-sm">
                <span>Facility Type</span>
              </div>
              <div className="nk-tb-col tb-col-mb">
                <span>Location</span>
              </div>
              <div className="nk-tb-col tb-col-md">
                <span>Emirate</span>
              </div>
            </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-lead">Muscat Private Hospital </span>
                    <span className="tb-sub">Oman</span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <Badge className="badge-dim success">Hospital</Badge>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Al Khuwair, Bausher Street </span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Abu Dhabi</span>
                  </div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-lead">Muscat Private Hospital </span>
                    <span className="tb-sub">Pharmacy</span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <Badge className="badge-dim success">Clinic</Badge>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub"> Al Khuwair, Bausher Street</span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Abu Dhabi</span>
                  </div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-lead"> Ahalia Hospital (Ahalia Group)</span>
                    <span className="tb-sub">United Arab Emirates</span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <Badge className="badge-dim success">Day Care</Badge>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Same Line Of Lulu Centre Oppos Hamdan Street Hamdan Street  </span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Abu Dhabi</span>
                  </div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-lead">Al Ahli Hosp Company Llc-Br 1 (Ahalia)</span>
                    <span className="tb-sub">United Arab Emirates</span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <Badge className="badge-dim success">Medical Lab</Badge>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">After The Bmw Showroom Tariff Road,Sanaya Mussafah </span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Abu Dhabi</span>
                  </div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-lead">Al Cornice Hospital Ivf Center </span>
                    <span className="tb-sub"> Seha United Arab Emirates</span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <Badge className="badge-dim success">Clinic</Badge>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Corniche Road Beside Sheraton Hotel Abudhabi</span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">Abu Dhabi</span>
                  </div>
                </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default UserHospitalList;
