import React, { useState,useEffect } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
} from "../components/Component";
import {getCurrentUser} from "../services/auth";
import SocialMediaFeed from "../components/partials/campaign/social-media-feed/SocialMediaFeed";
import CountryList from "../components/partials/campaign/custom-country-card/CountryList";
import UserHospitalList from "../components/user/UserHospitalList";
import UserDepartments from "../components/user/UserDepartments";
import UserEnquires from "../components/user/UserEnquires";
import UserJobs from "../components/user/UserJobs";

const UserDashboard = () => {
  const [sm, updateSm] = useState(false);
  const [userDetails, setUserDetails] = useState("");

  useEffect(() => {
    setUserDetails(getCurrentUser());
    const USR_TOKEN = localStorage.getItem("userToken");
    if (!USR_TOKEN) {
      window.location.href = `${process.env.PUBLIC_URL}/auth-login`;
    } else {
      setUserDetails(getCurrentUser());
    }
  }, []);
  return (
    <React.Fragment>
      <Head title="Dash Board" />
      <Content>
        <BlockHead size="sm" className="mt-2">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Welcome {userDetails.full_name}</BlockTitle>
              {/* <BlockDes className="text-soft">
                <p>Welcome to Hospital Management Dashboard.</p>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col /* xxl="4" */ md="8">
              <UserHospitalList />
            </Col>
            <Col /* xxl="4" */ md="4">
              <UserDepartments />
            </Col>

            <Col xxl="6" md="6">
              <UserEnquires />
            </Col>
            {/* <Col xxl="4" md="6">
              <CampaignOverview />
            </Col> */}
           {/*  <Col xxl="4" md="4">
              <ClicksbyLocation />
            </Col> */}
            <Col xxl="6" md="6">
              <UserJobs />
            </Col>
            <Col xxl="8" md="8">
              <SocialMediaFeed />
            </Col>
            <Col xxl="4" md="4">
              <CountryList />
            </Col>
            {/* <Col xxl="4" md="6">
              <WebConvrate />
            </Col> */}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default UserDashboard;
